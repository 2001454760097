const DUTCH = {
  belgium: 'België',
  germany: 'Duitsland',
  luxembourg: 'Luxemburg',
  netherlands: 'Nederland',
  denmark: 'Denemarken',
  Animate_years: 'Jaren afspelen',
  Close : 'Sluit',
  Country : 'Land',
  Female: 'Vrouw',
  Male: 'Man',
  No: 'Nee',
  Pups: 'Jongen',
  Region: 'Regio',
  Reproduction: 'Voortplanting',
  Restart: 'Reset kaart',
  Status: 'Status',
  Stop_animation: 'Stop animatie',
  Territory: 'Territorium',
  Year: 'Jaar',
  Yes: 'Ja',
  about_this_map: 'Over deze kaart',
  all_territories_from: 'Alle territoria van ',
  couple: 'paar',
  couples: 'paren',
  data_benelux_germany : 'Gegevens tot mei 2024',
  individual: 'individu',
  individuals: 'individuen',
  more_info: 'Meer informatie',
  pack: 'roedel',
  packs: 'roedels',
  press_enter_to_select: 'Druk op enter om te selecteren',
  pups: 'jongen',
  reproduction_took_place_in : 'Voortplanting vond plaats in ',
  select_region: 'Selecteer een regio',
  select_territory: 'Selecteer een territorium',
  select_country: 'Selecteer een land',
  selected: 'Geselecteerd',
  territorial_wolves_regions: 'Territoriale wolven leefden in de volgende regio\'s',
  with_a_total_of: 'met een totaal van',
  wolfterritories: 'Wolventerritoria',
  please_note: 'Nb: De gegevens van het huidige monitoringsjaar zijn nog niet compleet!',
  info_text: 'De kaart van de verspreiding van wolven in Nederland, België, Luxemburg en Duitsland is tot stand gekomen door een samenwerking van de overheden en/of uitvoeringsorganisaties die verantwoordelijk zijn voor de wolfmonitoring in deze landen. De data zijn de verantwoordelijkheid van deze landen en worden verzameld volgens hun nationale wolf monitoringplan. De data worden eenmaal per jaar herzien in november. Dan worden de data tot 1 mei van dat jaar toegevoegd. Daarbij kan ook data uit eerdere jaren worden toegevoegd.',
  nederland_bij12: 'Nederland: BIJ12 namens de Nederlandse provincies',
  belgie_anb: 'België: Agentschap voor Natuur en Bos (ANB) en Service public Wallonie (SPW)',
  luxemburg_anf: 'Luxemburg: Administration de la nature et des forêts (ANF)',
  duitsland_dbww: 'Duitsland: DBBW, <a target="_blank" class="hover:text-red underline" href="https://www.dbb-wolf.de">www.dbb-wolf.de</a>, data gedownload op 19 januari 2023',
  copyright: '© BIJ12, ANB, SPW, ANF, DBBW, januari 2023',
  funded: 'Dit project is gefinancierd en tot stand gekomen door het <a target="_blank" class="hover:text-red underline" href="https://www.eurolargecarnivores.eu">EuroLargeCarnivores LIFE project</a> en het Nederlandse Ministerie van Landbouw, Natuur en Voedselkwaliteit.',
  download_data: 'Download data'

}




export { DUTCH };