const FRENCH = {
  belgium: 'Belgique',
  germany: 'Allemagne',
  luxembourg: 'Luxembourg',
  netherlands: 'Pays-Bas',
  denmark: 'Danemark',
  Animate_years: 'Montrez les années successives',
  Close : 'Fermer',
  Country : 'Pays',
  Female: 'Femelle',
  Male: 'Mâle',
  No: 'Non',
  Pups: 'Louveteaux',
  Region: 'Région',
  Reproduction: 'Reproduction',
  Restart: 'Recommencer',
  Status: 'Statut',
  Stop_animation: 'Arrêt de l\'animation',
  Territory: 'Territoire',
  Year: 'Année',
  Yes: 'Oui',
  about_this_map: 'A propos de cette carte',
  all_territories_from: 'Tous les territoires de',
  couple: 'couple',
  couples: 'couples',
  data_benelux_germany : 'Données jusqu\'en mai 2024',
  individual: 'individu',
  individuals: 'individus',
  more_info: 'Plus d\'information',
  pack: 'meute',
  packs: 'meutes',
  press_enter_to_select: 'Appuyez sur la touche enter pour sélectionner',
  pups: 'louveteaux',
  reproduction_took_place_in : 'La reproduction a eu lieu dans',
  select_region: 'Sélectionnez une région',
  select_territory: 'Sélectionnez un territoire',
  select_country: 'Sélectionnez un pays',
  selected: 'Sélectionnez',
  territorial_wolves_regions: 'Les loups sedentaire vivaient dans les régions suivantes',
  with_a_total_of: 'avec un total de',
  wolfterritories: 'Territoires de loups',
  please_note: 'Notez que les données de cette année de monitoring(surveillance)  ne sont pas complètes.',
  info_text: 'La carte de la répartition des loups aux Pays-Bas, en Belgique, au Luxembourg et en Allemagne est le résultat d\'une coopération entre les autorités et/ou les organisations chargés de la surveillance des loups dans ces pays. Les données sont sous la responsabilité de ces pays et sont collectées conformément à leur plan national de surveillance du loup. Les données sont mises à jour une fois par an en novembre. Ensuite, les données jusqu\'au 1er mai de cette année sont ajoutées. Les données sont renouvelées chaque année en novembre (jusqu\'au mai de cette année la).',
  nederland_bij12: 'Pays Bas: BIJ12 au nom de province néerlandais',
  belgie_anb: 'Belgique: Agentschap voor Natuur en Bos (ANB) et Service public Wallonie (SPW)',
  luxemburg_anf: 'Luxembourg: Administration de la nature et des forêts (ANF)',
  duitsland_dbww: 'Allemagne: DBBW, <a target="_blank" class="hover:text-red underline" href="https://www.dbb-wolf.de">www.dbb-wolf.de</a>, données télécharger à 19 janvier 2023',
  copyright: '© BIJ12, ANB, SPW, ANF, DBBW, janvier 2023',
  funded: 'Ce projet a été financé et rendu possible par le projet <a target="_blank" class="hover:text-red underline" href="https://www.eurolargecarnivores.eu">LIFE EuroLargeCarnivores</a> et le ministère néerlandais de l\'agriculture, de la nature et de la qualité alimentaire.',
  download_data: 'Télécharger des données'

}

export { FRENCH };