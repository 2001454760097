import { render, staticRenderFns } from "./WolfMap.vue?vue&type=template&id=1f5d3222&scoped=true&"
import script from "./WolfMap.vue?vue&type=script&lang=js&"
export * from "./WolfMap.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f5d3222",
  null
  
)

export default component.exports